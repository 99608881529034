"use client";

import Image from "next/image";
import { Link } from "@/lib/i18n";
import Button from "../../Button/Button";
import { useEffect, useState } from "react";
import useAnimatedXP from "@/hooks/useAnimatedXP";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";

export default function Fragments({
    session,
    showTipsOnTop,
    withoutPopup = false,
}) {
    const locale = languageTag();

    const [openFragmentsInfos, setOpenFragmentsInfos] = useState(false);

    const [fragments, setFragments] = useState(0);

    const dynamicFragments = useAnimatedXP(fragments);

    useEffect(() => {
        if (session && session.user) {
            setFragments(session.user.fragments);
        }
    }, [session]);

    return (
        <div
            className="flex items-center gap-1.5 hover:background-blur dark:hover:background-blur-dark duration-100 cursor-pointer p-2 rounded-xl relative"
            onMouseOver={() => setOpenFragmentsInfos(true)}
            onMouseOut={() => setOpenFragmentsInfos(false)}
        >
            <Image
                src="/static/icons/fragment.svg"
                width={15}
                height={15}
                alt="Fragments"
            />
            <div className="text-sm text-gray-800 dark:text-lightBlue font-bold font-geistMono text-opacity-70">
                {dynamicFragments.toLocaleString(locale)}
            </div>
            {openFragmentsInfos && !withoutPopup && (
                <div
                    className={`absolute ${
                        showTipsOnTop ? "bottom-9" : "top-0"
                    } right-0 mt-8`}
                >
                    <div className="bg-white dark:bg-bgDark-700 p-5 mt-2 border dark:border-borderDark rounded-lg shadow-xl w-[400px] cursor-auto">
                        <div className="text-lg text-[#06050F] dark:text-white font-sourceSans font-bold">
                            {m.Fragments_title()}
                        </div>
                        <div className="text-[#06050F] text-opacity-60 dark:text-lightBlue font-geistSans text-sm mt-1">
                            {m.Fragments_description()}
                        </div>
                        <div className="mt-3 inline-block">
                            <Link href="/fragments">
                                <Button small>
                                    {m.Fragments_showMyFragments()}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
