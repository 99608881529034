export default function Button(props) {
    let size;

    if (props.type && props.type === "premium") {
        size = "px-8 py-4 rounded-full";
    } else if (props.type && props.type === "cta") {
        if (props.small) {
            size = "px-5 py-2 rounded-full";
        } else {
            size = "px-9 py-4 rounded-full";
        }
    } else {
        if (props.small) {
            size = "px-3 py-2 rounded-lg text-sm";
        } else if (props.large) {
            size = "px-5 py-2 rounded-lg";
        } else if (props.block) {
            size = "w-full py-4 rounded-lg";
        } else {
            size = "px-8 py-4 rounded-lg";
        }
    }

    let type;
    if (props.type && props.type === "light") {
        // light
        type =
            "border border-white bg-transparent text-white hover:bg-white hover:text-black";
    } else if (props.type && props.type === "white") {
        // white
        type = "bg-white text-black hover:bg-gray-300 hover:text-black";
    } else if (props.type && props.type === "success") {
        // success
        type = "bg-green-500 text-white hover:bg-green-400";
    } else if (props.type && props.type === "warning") {
        // warning
        type = "bg-yellow-500 text-white hover:bg-yellow-400";
    } else if (props.type && props.type === "danger") {
        // danger
        // type = "bg-red-500 text-white hover:bg-red-400";
        type = "bg-red-300 text-red-800 hover:bg-red-400 hover:text-white";
    } else if (props.type && props.type === "light-purple") {
        // light purple
        type =
            "border border-primary dark:border-primary-100 bg-transparent text-primary dark:text-primary-100 hover:bg-primary dark:hover:bg-primary-100 hover:text-white dark:hover:text-white";
    } else if (props.type && props.type === "gold") {
        // gold
        type = "bg-gold text-white hover:bg-gold-900";
    } else if (props.type && props.type === "light-gold") {
        // light gold
        type =
            "border border-gold bg-transparent text-gold hover:bg-gold hover:text-white";
    } else if (props.type && props.type === "oldgold") {
        // oldgold
        type = "bg-gold-900 text-white hover hover:bg-opacity-30";
    } else if (props.type && props.type === "confidence") {
        // confidence
        type = "bg-confidence text-white hover:bg-confidence-100";
    } else if (props.type && props.type === "outline-confidence") {
        // outline-confidence
        type =
            "border border-confidence text-confidence bg-transparent hover:bg-confidence-100 hover:border-transparent hover:text-white";
    } else if (props.type && props.type === "excitation") {
        // excitation
        type = "bg-excitationOrange text-white hover:bg-excitationRed";
    } else if (props.type && props.type === "pop") {
        // pop
        type =
            "from-excitationOrange bg-gradient-to-r to-excitationRed text-white hover:bg-gradient-to-l hover:opacity-90";
    } else if (props.type && props.type === "outline-pop") {
        // outline
        type =
            "border border-excitationOrange text-excitationOrange bg-transparent hover:border-excitationRed hover:text-white hover:bg-gradient-to-r from-excitationOrange to-excitationRed";
    } else if (props.type && props.type === "phantom") {
        // phantom
        type =
            "text-primary hover:text-primary-100 border border-gray-100 dark:border-borderDark hover:border-primary-100 dark:hover:border-primary-100";
    } else if (props.type && props.type === "premium") {
        // premium
        type =
            "bg-gradient-to-r from-primary to-primary-100 text-white hover:bg-gradient-to-l hover:opacity-90";
    } else if (props.type && props.type === "cta") {
        // cta
        type = "bg-blue-500 text-white hover:bg-blue-400";
    } else if (props.type && props.type === "google") {
        // google
        size = "px-8 py-4 rounded-full w-full";
        type =
            "bg-white text-black hover:bg-primary border dark:border-borderDark hover:bg-opacity-5 hover:text-black dark:bg-black dark:text-white dark:hover:bg-primary dark:hover:bg-opacity-10 dark:hover:text-white";
    } else {
        // default
        type = "bg-primary text-white hover:bg-primary-300";
    }

    let disabled;
    if (props.isLoading || props.disabled) {
        disabled = "cursor-not-allowed opacity-50";
    } else {
        disabled = "cursor-pointer";
    }

    return (
        <button
            className={`button group ${size} ${type} ${
                props.className || ""
            } ${disabled}`}
            onClick={props.onClick}
            ref={props.ref}
            disabled={props.disabled || props.isLoading}
        >
            <div
                className={`flex items-center justify-center ${
                    props.childrenClassName || ""
                }`}
            >
                {props.children}
                {props.type && props.type === "premium" && (
                    <div>
                        <div class="relative flex h-4 w-4 pl-1">
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-3 w-3 bg-white"></span>
                        </div>
                    </div>
                )}
            </div>
        </button>
    );
}
