const calculatePlayerLevel = (xp) => {
    const levels = [
        { level: 1, xpRequired: 0 },
        { level: 2, xpRequired: 50 },
        { level: 3, xpRequired: 150 },
        { level: 4, xpRequired: 400 },
        { level: 5, xpRequired: 800 },
        { level: 6, xpRequired: 1300 },
        { level: 7, xpRequired: 1800 },
        { level: 8, xpRequired: 2300 },
        { level: 9, xpRequired: 3000 },
        { level: 10, xpRequired: 3700 },
        { level: 11, xpRequired: 4400 },
        { level: 12, xpRequired: 5100 },
        { level: 13, xpRequired: 5800 },
        { level: 14, xpRequired: 6500 },
        { level: 15, xpRequired: 7200 },
        { level: 16, xpRequired: 7900 },
        { level: 17, xpRequired: 8600 },
        { level: 18, xpRequired: 9300 },
        { level: 19, xpRequired: 10000 },
    ];

    // Calcul du niveau actuel en fonction de l'XP
    let currentLevel = levels[0]; // Démarre au niveau 1

    // Boucle sur les niveaux prédéfinis pour voir où se situe l'XP
    for (let i = 1; i < levels.length; i++) {
        if (xp >= levels[i].xpRequired) {
            currentLevel = levels[i];
        } else {
            break;
        }
    }

    // Si l'XP dépasse le niveau 19, continuer avec des incréments de 1000 XP
    if (xp >= 10000) {
        const additionalLevels = Math.floor((xp - 10000) / 1000);
        currentLevel = {
            level: 19 + additionalLevels,
            xpRequired: 10000 + additionalLevels * 1000,
        };
    }

    // Calculer le prochain niveau
    const nextLevel =
        currentLevel.level < 19
            ? levels.find((l) => l.level === currentLevel.level + 1)
            : {
                  level: currentLevel.level + 1,
                  xpRequired: currentLevel.xpRequired + 1000,
              };

    const xpForNextLevel = nextLevel.xpRequired;
    const xpToNextLevel = xpForNextLevel - xp;

    // Retour des informations de progression
    return {
        level: currentLevel.level,
        xpRequired: currentLevel.xpRequired,
        xpForNextLevel: xpForNextLevel,
        xpToNextLevel: xpToNextLevel,
        progressToNextLevel: Math.round(
            ((xp - currentLevel.xpRequired) /
                (xpForNextLevel - currentLevel.xpRequired)) *
                100,
        ),
    };
};

export default calculatePlayerLevel;
