// file generated by the Paraglide-Next init command
import {
    PrefixStrategy,
    DetectionStrategy,
    Navigation,
    Middleware,
} from "@inlang/paraglide-next";
import * as m from "@/paraglide/messages";

/**
 * @type {import("@inlang/paraglide-next").PrefixStrategy<
 *     import("@/paraglide/runtime").AvailableLanguageTag
 * >}
 */
export const strategy = PrefixStrategy({
    prefixDefault: "never",
    pathnames: {
        "/courses": m.courses_pathname,
        "/course/[slug]": m.course_pathname,
        "/course/[slug]/[partNumber]/[sessionNumber]":
            m.course_content_pathname,
        "/course/le-programme-rocket/dashboard": m.program_dashboard_pathname,
        "/accelerators": m.accelerators_pathname,
        "/accelerators/[slug]": m.accelerator_pathname,
        "/accelerators/[slug]/dashboard": m.accelerator_dashboard_pathname,
        "/accelerators/[slug]/entry-document":
            m.accelerator_entryDocument_pathname,
        "/accelerators/[slug]/[moduleNumber]/[partNumber]/[sessionNumber]":
            m.accelerator_content_pathname,
        "/glossaries": m.glossaries_pathname,
        "/glossaries/[slug]": m.glossary_pathname,
        "/glossaries/[slug]/[termSlug]": m.term_pathname,
        "/mentoring": m.mentoring_pathname,
        "/mentoring/[pseudo]": m.mentorprofile_pathname,
        "/mentor": m.mentor_pathname,
        "/writer": m.writer_pathname,
        "/writer/files": m.writer_files_pathname,
        "/writer/articles/[slug]": m.writer_article_pathname,
        "/r/[slug]": m.article_pathname,
        "/instructor": m.instructor_pathname,
        "/instructor/courses/[slug]": m.instructor_course_pathname,
        "/articles": m.articles_pathname,
        "/call/[token]": m.call_pathname,
        "/certifications/[certificationNumber]": m.certification_pathname,
        "/messenger": m.messenger_pathname,
        "/u/[pseudo]": m.profile_pathname,
        "/account": m.account_pathname,
        "/series/[slug]": m.serie_pathname,
        "/series/[slug]/[seasonNumber]/[episodeNumber]": m.serie_watch_pathname,
        "/login": m.login_pathname,
        "/signup": m.signup_pathname,
        "/verify-account": m.verifyAccount_pathname,
        "/security/forgotten-password": m.forgottenPassword_pathname,
        "/pricing": m.pricing_pathname,
        "/join-instructors": m.joinInstructor_pathname,
        "/join-mentors": m.joinMentor_pathname,
        "/legal-mentions": m.legalMentions_pathname,
        "/privacy": m.privacy_pathname,
        "/events": m.events_pathname,
        "/terms-of-sale": m.termsOfSale_pathname,
        "/community": m.community_pathname,
        "/community/discussions/[id]": m.community_discussion_pathname,
        "/press": m.press_pathname,
        "/accelerators/rocket": m.accelerator_rocket_pathname,
    },
});

export const middleware = Middleware({ strategy });
export const { Link, useRouter, usePathname, redirect, permanentRedirect } =
    Navigation({ strategy });
