"use client";

import calculatePlayerLevel from "@/helpers/calculatePlayerLevel";
import useAnimatedXP from "@/hooks/useAnimatedXP";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ConfettiExplosion from "react-confetti-explosion";
import { AnimatePresence, motion } from "framer-motion";
import * as m from "@/paraglide/messages.js";
import { languageTag } from "@/paraglide/runtime";

export default function Level({ session, user }) {
    const locale = languageTag();

    const [level, setLevel] = useState(0);
    const [xp, setXp] = useState(0);
    const [xpRequired, setXpRequired] = useState(0);
    const [xpForNextLevel, setXpForNextLevel] = useState(0);
    const [progressToNextLevel, setProgressToNextLevel] = useState(0);
    const [previousLevel, setPreviousLevel] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [confetti, setConfetti] = useState(false);
    const [recentXpGain, setRecentXpGain] = useState(0); // Pour les gains d'XP
    const [recentXpLoss, setRecentXpLoss] = useState(0); // Pour les pertes d'XP
    const [showModal, setShowModal] = useState(false);

    const displayedXP = useAnimatedXP(xp);
    const displayedXPForNextLevel = useAnimatedXP(xpForNextLevel);
    const displayedLevel = useAnimatedXP(level);
    const displayedProgressToNextLevel = useAnimatedXP(progressToNextLevel);

    useEffect(() => {
        if (session && session.user) {
            const { level, xpRequired, xpForNextLevel, progressToNextLevel } =
                calculatePlayerLevel(session.user.xp);

            const xpDifference = session.user.xp - xp; // Calculer la différence d'XP (gain ou perte)

            setLevel(level);
            setXp(session.user.xp);
            setXpRequired(xpRequired);
            setXpForNextLevel(xpForNextLevel);
            setProgressToNextLevel(progressToNextLevel);

            if (xpDifference > 0 && !isFirstLoad) {
                // Gain d'XP
                setRecentXpGain(xpDifference);
                setRecentXpLoss(0); // Réinitialiser la perte si gain
                setTimeout(() => {
                    setRecentXpGain(0); // Réinitialiser après 3 secondes
                }, 3000);
            } else if (xpDifference < 0 && !isFirstLoad) {
                // Perte d'XP
                setRecentXpLoss(Math.abs(xpDifference));
                setRecentXpGain(0); // Réinitialiser le gain si perte
                setTimeout(() => {
                    setRecentXpLoss(0); // Réinitialiser après 3 secondes
                }, 3000);
            }

            if (level > previousLevel && !isFirstLoad) {
                toast.success(
                    `Bravo ! Vous avez atteint le niveau ${level} ! 🎉`,
                );
                setConfetti(true);
                setTimeout(() => {
                    setConfetti(false);
                }, 3000);
            }

            setPreviousLevel(level);
            setIsFirstLoad(false);
        }
    }, [session]);

    useEffect(() => {
        if (user) {
            const { level, xpRequired, xpForNextLevel, progressToNextLevel } =
                calculatePlayerLevel(user.xp);

            const xpDifference = user.xp - xp; // Calculer la différence d'XP (gain ou perte)

            setLevel(level);
            setXp(user.xp);
            setXpRequired(xpRequired);
            setXpForNextLevel(xpForNextLevel);
            setProgressToNextLevel(progressToNextLevel);

            if (xpDifference > 0 && !isFirstLoad) {
                // Gain d'XP
                setRecentXpGain(xpDifference);
                setRecentXpLoss(0); // Réinitialiser la perte si gain
                setTimeout(() => {
                    setRecentXpGain(0); // Réinitialiser après 3 secondes
                }, 3000);
            } else if (xpDifference < 0 && !isFirstLoad) {
                // Perte d'XP
                setRecentXpLoss(Math.abs(xpDifference));
                setRecentXpGain(0); // Réinitialiser le gain si perte
                setTimeout(() => {
                    setRecentXpLoss(0); // Réinitialiser après 3 secondes
                }, 3000);
            }

            if (level > previousLevel && !isFirstLoad) {
                toast.success(m.Level_levelUp({ level }));
                setConfetti(true);
                setTimeout(() => {
                    setConfetti(false);
                }, 3000);
            }

            setPreviousLevel(level);
            setIsFirstLoad(false);
        }
    }, [user]);

    return (
        <>
            {/* About experience points */}
            <AnimatePresence>
                {showModal && (
                    <div className="background-blur-dark fixed top-0 left-0 bottom-0 right-0 flex justify-center lg:items-center z-[100000]">
                        <motion.div
                            variants={{
                                hidden: { opacity: 0, y: 15 },
                                visible: { opacity: 1, y: 0 },
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            className="flex lg:flex-row flex-col rounded-xl shadow lg:w-[900px] overflow-y-auto m-5 relative"
                        >
                            <div className="absolute top-5 right-5 z-10">
                                <svg
                                    onClick={() => setShowModal(false)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8 text-blue-500 cursor-pointer"
                                    viewBox="0 0 24 24"
                                >
                                    <g fill="currentColor">
                                        <path d="M10.03 8.97a.75.75 0 0 0-1.06 1.06L10.94 12l-1.97 1.97a.75.75 0 1 0 1.06 1.06L12 13.06l1.97 1.97a.75.75 0 0 0 1.06-1.06L13.06 12l1.97-1.97a.75.75 0 1 0-1.06-1.06L12 10.94l-1.97-1.97Z"></path>
                                        <path
                                            fillRule="evenodd"
                                            d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75S22.75 17.937 22.75 12S17.937 1.25 12 1.25ZM2.75 12a9.25 9.25 0 1 1 18.5 0a9.25 9.25 0 0 1-18.5 0Z"
                                            clipRule="evenodd"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="bg-white dark:bg-box md:p-10 p-5 rounded-xl">
                                <h2 className="text-2xl font-bold font-sourceSans dark:text-white">
                                    {m.Level_modale_title()}
                                </h2>
                                <div className="mt-3 dark:text-lightBlue">
                                    <p>{m.Level_modale_description1()}</p>
                                    <p>{m.Level_modale_description2()}</p>
                                    <p>{m.Level_modale_description3()}</p>
                                </div>

                                <div className="bg-light dark:bg-bgDark-300 p-5 rounded-xl mt-5">
                                    <h3 className="text-xl dark:text-white font-bold font-sourceSans">
                                        {m.Level_modale_howToEarnXP_title()}
                                    </h3>
                                    <div className="mt-3 dark:text-lightBlue font-geistSans">
                                        {m.Level_modale_howToEarnXP_description()}
                                        <ul className="list-disc list-inside ml-8">
                                            <li>
                                                {m.Level_modale_howToEarnXP_1()}
                                            </li>
                                            <li>
                                                {m.Level_modale_howToEarnXP_2()}
                                            </li>
                                            <li>
                                                {m.Level_modale_howToEarnXP_3()}
                                            </li>
                                            <li>
                                                {m.Level_modale_howToEarnXP_4()}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
            <div
                className="text-[#05060F] text-opacity-40 dark:text-lightBlue text-xs cursor-pointer"
                onClick={() => setShowModal(true)}
            >
                {m.Level_level()} {displayedLevel.toLocaleString(locale)} (
                {displayedProgressToNextLevel}% -{" "}
                {displayedXP.toLocaleString(locale)}/
                {displayedXPForNextLevel.toLocaleString(locale)} {m.Level_xp()})
                {recentXpGain > 0 && (
                    <span className="text-green-500">
                        {" "}
                        (+{recentXpGain.toLocaleString(locale)} {m.Level_xp()})
                    </span>
                )}
                {recentXpLoss > 0 && (
                    <span className="text-red-500">
                        {" "}
                        (-{recentXpLoss.toLocaleString(locale)} {m.Level_xp()})
                    </span>
                )}
            </div>
            {confetti && <ConfettiExplosion duration={3000} zIndex={1000} />}
            <div className="h-2 bg-gray-200 dark:bg-bgDark-300 rounded-lg overflow-hidden mt-1">
                <div
                    className="h-full bg-primary"
                    style={{
                        width: `${displayedProgressToNextLevel}%`,
                    }}
                ></div>
            </div>
        </>
    );
}
