import { useEffect, useState } from "react";

const useAnimatedXP = (xp, duration = 100) => {
    const [displayedXP, setDisplayedXP] = useState(xp); // Valeur initiale d'XP affichée

    useEffect(() => {
        // Si l'XP actuelle est égale à l'XP affichée, pas besoin d'animer
        if (xp === displayedXP) return;

        const increment = (xp - displayedXP) / (duration / 16); // Animation à 60 fps
        let currentValue = displayedXP;

        const interval = setInterval(() => {
            currentValue += increment;
            if (
                (increment > 0 && currentValue >= xp) ||
                (increment < 0 && currentValue <= xp)
            ) {
                currentValue = xp; // S'assurer que l'XP finale est atteinte
                clearInterval(interval);
            }
            setDisplayedXP(Math.floor(currentValue)); // Arrondi à l'entier inférieur
        }, 16); // Exécute toutes les 16ms (60 FPS)

        return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
    }, [xp, displayedXP, duration]); // Dépend de l'XP actuelle et affichée

    return displayedXP; // Retourne la valeur animée
};

export default useAnimatedXP;
