import { languageTag } from "@/paraglide/runtime";
import moment from "moment-timezone";
import "moment/locale/fr";

export default function LastSevenDaysProgress({ activity }) {
    // Récupérer la langue de l'utilisateur
    const locale = languageTag();

    // Générer les 7 derniers jours à partir de la date actuelle
    const getLast7Days = () => {
        const days = [];
        for (let i = 6; i >= 0; i--) {
            const date = moment().locale(locale).subtract(i, "days");
            days.push({
                day: date.format("dd").charAt(0), // Récupérer la première lettre du jour (L, M, etc.)
                date: date.format("YYYY-MM-DD"), // Formater la date pour l'activité
                isToday: i === 0, // Indique si c'est aujourd'hui
            });
        }
        return days;
    };

    const last7Days = getLast7Days();

    return (
        <div className="flex justify-between gap-3 mt-3 h-[100px] text-xs">
            {last7Days.map(({ day, date, isToday }, index) => (
                <div
                    key={index}
                    className="h-full flex flex-col items-center uppercase"
                >
                    <div
                        className={`rounded-full p-1 w-3 flex-1 ${
                            activity[date] > 0
                                ? "bg-primary"
                                : isToday
                                ? "bg-animate" // Classe CSS qui anime le changement de couleur
                                : "bg-[#06050F] bg-opacity-10 dark:bg-bgDark-550"
                        }`}
                    ></div>
                    {day} {/* Afficher la première lettre du jour */}
                </div>
            ))}
        </div>
    );
}
